<template>
  <g>
    <circle
      v-for="(coordinate, index) in points"
      :key="index"
      :id="`p${index}`"
      :class="`drag${coordinate.pk}`"
      :stroke="coordinate.color"
      :fill="coordinate.color"
      :cx="btnRenderSide == 1 ? width - (coordinate.x * 60) * 1.5 * svgScale * (sliderScale != 0 ? 1 + (sliderScale * 0.10) : 1) : (coordinate.x * 60 + x_offset) * 1.5 * svgScale * (sliderScale != 0 ? 1 + (sliderScale * 0.10) : 1)"
      :cy="btnRenderSide == 2 ? (coordinate.y * -200 + (y_offset * 2)) * 0.4 * (sliderScale != 0 ? 1 + (sliderScale * 0.10) : 1) : height - (coordinate.y * -200 + y_offset) * 0.4 * (sliderScale != 0 ? 1 + (sliderScale * 0.10) : 1)"
      :r="100 * svgScale * (sliderScale != 0 ? 1 + (sliderScale * 0.10) : 1)"
      @mouseover="
        showTooltip({
          x:
            (coordinate.x * 40 + x_offset) * 1.5 * svgScale * (sliderScale != 0 ? 1 + (sliderScale * 0.10) : 1),
          y:
            (height * 0.10 * svgScale) - ((coordinate.y * 200 + (y_offset)) * 0.4),
          
          text: `Name: ${coordinate.name} [x:${coordinate.x} y:${coordinate.y}]`,
        })
      "
      @mouseout="hideTooltip"
    />
  </g>
</template>
<script>
export default {
  name: "PressurePinView",
  props: {
    svgScale: Number,
    x_offset: Number,
    y_offset: Number,
    width: Number,
    height: Number,
    sliderScale: Number,
    btnRenderSide: Number,
  },
  computed: {
    points() {
      return this.$store.getters["svgdrawing/pressurepinPlot"];
    }
  },
  methods: {
    showTooltip(payload) {
      this.$store.dispatch("ui/hoverText", payload.text);
      this.$emit("toggleHoverText", true);
    },
    hideTooltip() {
      this.$store.dispatch("ui/hoverText", null);
      this.$emit("toggleHoverText", false);
    },
  },
};
</script>
